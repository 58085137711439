/**
 * Normalize.css and sTailwind's base styles.
 */

@import-normalize;
@import 'tailwindcss/base';

/**
 * Custom base styles, applied after the tailwind-base classes
 */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
	--visited-color: #a25be0;
	--visited-bg-color: #dcdcdc8a;
}

html {
	font-size: 62.5%;
	font-family: 'Roboto', sans-serif;
	background-color: #121212;
}

body {
	font-size: 13px;
	line-height: 1.4;
	overflow-x: hidden;
}

html,
body,
#root {
	position: relative;
	margin: 0;
	min-height: 100vh;
}

h1,
.h1 {
	font-size: 24px;
}

h2,
.h2 {
	font-size: 20px;
}

h3,
.h3 {
	font-size: 16px;
}

h4,
.h4 {
	font-size: 15px;
}

h5,
.h5 {
	font-size: 13px;
}

h6,
.h6 {
	font-size: 12px;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
	z-index: 99;
}

a[role='button'] {
	text-decoration: none;
}

[role='tooltip'] {
	z-index: 9999;
}

[class^='MuiTooltip-tooltip-'] {
	background-color: #22d3ee;
	color: #000;
}

[class^='MuiTooltip-arrow-'] {
	color: #22d3ee;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
	::-webkit-scrollbar {
		width: 8px;
		height: 8px;
		background-color: rgba(0, 0, 0, 0);
	}

	::-webkit-scrollbar:hover {
		width: 8px;
		height: 8px;
		background-color: rgba(0, 0, 0, 0.06);
	}

	::-webkit-scrollbar-thumb {
		border: 2px solid transparent;
		border-radius: 20px;
	}

	::-webkit-scrollbar-thumb:active {
		border-radius: 20px;
	}
}

form label {
	z-index: 99;
}

body.no-animate *,
body.no-animate *::before,
body.no-animate *::after {
	transition: none !important;
	animation: none !important;
}

button:focus {
	outline: none;
}

@keyframes autofill {
	0%,
	100% {
		background: transparent;
	}
}

input:-webkit-autofill {
	animation-delay: 1s;
	animation-name: autofill !important;
	animation-fill-mode: both;
	-webkit-box-shadow: 0 0 0 100px transparent inset !important;
}

:focus {
	outline-color: transparent;
}

.hasVisited th {
	background-color: var(--visited-bg-color);
	color: var(--visited-color);
}

.hasVisited td {
	background-color: var(--visited-bg-color);
}

.hasVisited-report-row td {
	background-color: var(--visited-bg-color);
	color: var(--visited-color);
}

.hasVisited-report-row td p {
	color: var(--visited-color) !important;
}

.table-container {
	height: 70vh;
}
@media only screen and (max-width: 1400px) {
	.table-container {
		height: 65vh;
	}
}
